.App {
  height: 100%;
  text-align: left;
  top: 0px;
}

.center {
  text-align: center;
}

.sidbar {
  top: 100%;
}

.margin {
  margin-bottom: 2%;
}
.page {
  position: relative;
  overflow: auto;
  max-height: 84vh;
  margin: 30px;
}
.timeoutWarning {
  width: 30%;
  padding: 5px;
  margin: 0 auto;
  clear: both;
}
@media only screen and (max-width: 768px) {
  .page {
    position: relative;
    max-height: 72vh;
    overflow: auto;
  }
  .footer {
    left: 0;
  }
}

.header {
  background-color: rgb(66, 69, 89);
  border-bottom: #00A758 solid 4px
  /* padding: 10px 0 10px 0; */
}

.header .squarelogo {
  width: 45px;
  height: 45px;
}

.header ul:nth-child(2) {
  padding: 0;
  margin: 0;
}

.header h1 {
  font-size: 1.75rem;
  margin-top: 5px;
  margin-left: 15px;
}

.header #header > button {
  border: none !important;
}

.footer {
  left: 0;
  right: 0;
  text-align: center;
}

.sr-only {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  top: auto;
  overflow: hidden;
}

.timeoutButton {
  float: right !important;
}
.nativeTable {
  font-family: "Manulife JH Sans","Helvetica","Arial",sans-serif;
  font-weight: 300;
  table-layout: fixed;
}
.nativeTable tr {
  border-bottom: 1px solid #dee2e6;
}